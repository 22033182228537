import React from "react"
import Layout from "../../components/layout"
import BannerActions from "../../components/banners/bannerActions"
import { Section, Container } from "../../components/layoutComponents"
import SpecificProject from "../../components/gallery/specificProject"
import Banner from "../../images/banner.png"
import Title from "../../components/title"
import ResidentialGallery from "../../components/gallery/residentialGallery"
import FeaturedTestimonial from "../../components/testimonials/featuredTestimonial"
import SEO from "../../components/seo"

export default function ResidentialProjects() {
  return (
    <Layout>
      <SEO title="Residential Electrical Projects" />
      <BannerActions img={Banner} />
      <Section>
        <Container className="spacing">
          <Title title="residential electrical project gallery" />
          <ResidentialGallery />
          {/* <SpecificProject
            img={Project15}
            altText="residential electrical project"
            description="roughing in a custom home"
          />
          <SpecificProject
            img={Project16}
            altText="residential electrical project"
            description="residential rough in"
          />
          <SpecificProject
            img={Project13}
            altText="residential electrical project"
            description="whole home standby generators"
          />
          <SpecificProject
            img={Project14}
            altText="residential electrical project"
            description="roughing in a container office"
          />
          <SpecificProject
            img={Project1}
            altText="residential electrical project"
            description="100A apartment load centre"
          />
          <SpecificProject
            img={Project2}
            altText="residential electrical project"
            description="(2) 200A residential distribution"
          />
          <SpecificProject
            img={Project3}
            altText="residential electrical project"
            description="200A through roof / steel mast"
          />
          <SpecificProject
            img={Project4}
            altText="residential electrical project"
            description="lake muskoka boathouse"
          />
          <SpecificProject
            img={Project5}
            altText="residential electrical project"
            description="clean and simple"
          />
          <SpecificProject
            img={Project6}
            altText="residential electrical project"
            description="condo pot lights"
          />
          <SpecificProject
            img={Project7}
            altText="residential electrical project"
            description="soffit pot lights"
          />
          <SpecificProject
            img={Project8}
            altText="residential electrical project"
            description="(2) 200A residential distribution meters"
          />
          <SpecificProject
            img={Project9}
            altText="residential electrical project"
            description="residential kitchen"
          />
          <SpecificProject
            img={Project10}
            altText="residential electrical project"
            description="piping A/C disconnect"
          />
          <SpecificProject
            img={Project11}
            altText="residential electrical project"
            description="trenching 200A cottage service in muskoka"
          />
          <SpecificProject
            img={Project12}
            altText="residential electrical project"
            description="operating trench machine for 200A service"
          /> */}
        </Container>
      </Section>
      <FeaturedTestimonial
        testimonial="Excellent work executed to electrical safety regulation standards, quick to respond, friendly, adaptable and a strong resource for lighting concepts and layouts."
        company="Homeowner"
        name="ROSALIND SALTER"
      />
    </Layout>
  )
}
