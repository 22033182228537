import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Section, Container } from "../layoutComponents"
import { ButtonUnderline } from "../buttons"
import Stars from "./stars"
import Avatar from "./avatar"
import { BsArrowRight } from "react-icons/bs"

const Wrapper = styled.div`
  background: var(--clr-dark);
`

const Center = styled.div`
  display: grid;
  place-items: center;
`

const Text = styled.div`
  color: var(--txt-light);
  p {
    font-weight: var(--fw-300);
    font-size: var(--fs-3);
    font-style: italic;
    line-height: 1.1;
    width: 100%;
    max-width: 45ch;
    margin-left: auto;
    margin-right: auto;
  }
`

export default function FeaturedTestimonial(props) {
  return (
    <Wrapper>
      <Section>
        <Container>
          <div className="spacing center">
            <Center>
              <Stars />
            </Center>
            <Text>
              <p>"{props.testimonial}"</p>
            </Text>
            <hr />
            <Avatar company={props.company} name={props.name} />
          </div>
          {/* <Link className="inline-link" to="/testimonials">
            View all reviews &#8594;
          </Link> */}
        </Container>
      </Section>
    </Wrapper>
  )
}
