import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Container } from "../layoutComponents"
import { ButtonUnderline } from "../buttons"
import { BsArrowRight } from "react-icons/bs"

const device = {
  md: "48em",
}

const Flex = styled.div`
  background: var(--clr-light-secondary);
  display: flex;

  @media screen and (max-width: ${device.md}) {
    flex-direction: column;
  }

  & > * {
    width: 100%;
  }
`

const ProjectImg = styled.div`
  img {
    object-fit: cover;
  }
`
const ProjectText = styled.div`
  height: 100%;
  padding: 2em;
  align-self: center;
  display: flex;
  flex-direction: column;
`

export default function SpecificProject(props) {
  return (
    <Flex>
      <ProjectImg>
        <img src={props.img} alt={props.altText} />
      </ProjectImg>
      <ProjectText className="spacing">
        <p className="body body--large">{props.description}</p>
        <ButtonUnderline to="/contact">
          <div>
            get a quote <BsArrowRight size={22} />{" "}
          </div>
        </ButtonUnderline>
      </ProjectText>
    </Flex>
  )
}
