import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { ButtonUnderline } from "../buttons"
import { BsArrowRight } from "react-icons/bs"

const device = {
  md: "48em",
}

const Flex = styled.div`
  background: var(--clr-light-secondary);
  display: flex;

  @media screen and (max-width: ${device.md}) {
    flex-direction: column;
  }

  & > * {
    width: 100%;
  }
`

const ProjectImg = styled.div``
const ProjectText = styled.div`
  height: 100%;
  padding: 2em;
  align-self: center;
  display: flex;
  flex-direction: column;
`

export default function ResidentialGallery() {
  return (
    <div>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/roughing-in-custom-home.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">roughing in a custom home</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/residential-rough-in.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">residential rough in</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/whole-home-standby-generators.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">whole home standby generators</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/roughing-in-container-office.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">roughing in a container office</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/100a-apartment-load-centre.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">100A apartment load centre</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/2-200a-residential-distribution.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">(2) 200A residential distribution</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/200a-through-roof.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">200A through roof / steel mast</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/lake-muskoka-boathouse.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">lake muskoka boathouse</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/clean-and-simple.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">clean and simple</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/condo-potlights.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">condo pot lights</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/soffit-potlights.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">soffit pot lights</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/2-200a-residential-distribution-meters.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">
            (2) 200A residential distribution meters
          </p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/residential-kithcen.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">residential kitchen</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/piping-ac-disconnect.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">piping A/C disconnect</p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/trenching-200a-cottage-service-in-muskoka.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">
            trenching 200A cottage service in muskoka
          </p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
      <Flex>
        <ProjectImg>
          <StaticImage
            src="../../images/projects/residential/operating-trench-machine-for-200a-service.jpg"
            alt=""
            placeholder="blurred"
            objectFit="cover"
          />
        </ProjectImg>
        <ProjectText className="spacing">
          <p className="body body--large">
            operating trench machine for 200A service
          </p>
          <ButtonUnderline to="/contact">
            <div>
              get a quote <BsArrowRight size={22} />{" "}
            </div>
          </ButtonUnderline>
        </ProjectText>
      </Flex>
    </div>
  )
}
